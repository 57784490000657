"use strict";

// NOTE TO SELF
// Found better example code to reference for improved a11y on drop menus...
// https://www.w3.org/TR/wai-aria-practices/examples/menubar/menubar-1/menubar-1.html
//
// More at
// https://www.w3.org/TR/wai-aria-practices/
// https://www.w3.org/TR/wai-aria-practices/examples/

function doMobileNavigation(
	attachButtonTo,
	buttonPosition,
	navigationContainer
) {
	const html      = document.querySelector('html');
	const theMenu   = document.querySelector(navigationContainer);
	const subNavs   = theMenu.querySelectorAll('[data-submenu-status]');
	const templateToggleNav = `
		<button id='mainNavToggle' aria-label="Open Main Navigation" role="button">
			Menu
		</button>
	`;

	// initialise everything
		html.dataset.sidebarNavigation = 'closed';

		document.querySelector(attachButtonTo)
			.insertAdjacentHTML(
				buttonPosition,
				templateToggleNav
		);

		// Now the button's in the DOM we can grab it
		const navToggle = document.querySelector('#mainNavToggle');

	// Accessibility enhancements for the dynamic toggling control
	// add ARIA labels to things that need them
		theMenu.setAttribute("aria-labelledby", "mainNavToggle");

	// handle clicks on the menu toggle button to show/hide the main panel
		navToggle.addEventListener("click",(e) => {
			let currentNavStatus = html.dataset.sidebarNavigation;

			if (currentNavStatus == 'open') {
				// is open now, so the click is to close it
				navToggle.setAttribute("aria-expanded", false); // applies new value
				navToggle.setAttribute('aria-label',"Open Main Navigation");
				navToggle.textContent = "Menu";
				html.dataset.sidebarNavigation = "closed";
			} else {
				// is closed now, so the click is to open it
				navToggle.setAttribute("aria-expanded", true); // applies new value
				navToggle.setAttribute('aria-label',"Close Main Navigation");
				navToggle.textContent = "Close";
				html.dataset.sidebarNavigation = "open";
			}
		});
}

function undoMobileNavigation() {
	document.querySelector('html').removeAttribute('data-sidebar-navigation');

	let navMenuToggle = document.querySelector('#mainNavToggle');
	if( navMenuToggle ) {
		document.querySelector('#mainNavToggle').remove();
		document.querySelector('#site_navigation').removeAttribute('aria-labelledby');
	}
}

let screenWidth = window.matchMedia('(max-width: 1023px)');

// initial load
if( screenWidth.matches ) {
	doMobileNavigation(
		'header .siteTitle',
		'afterend',
		'header .topNav'
	);
} else {
	undoMobileNavigation();
}

// watch for changes in window size
screenWidth.addEventListener("change", (e) => {
	if( e.matches ) {
			doMobileNavigation(
			'header .siteTitle',
			'afterend',
			'header .topNav'
		);
	} else {
		undoMobileNavigation();
	}
});
